import axios from 'axios'

const path = process.env.VUE_APP_BASE_API + 'biddingSystemProcesses'

export default {
    getAll(params) {
        return axios.get(`${path}`, {
            params: params
        })
    },
}