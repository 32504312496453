<template>
  <div>
    <section class="bg-half bg-soft-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h1 class="title mb-0">Cadastro de Fornecedor</h1>
              <h5 class="text-muted">Crie uma conta e comece imediatamente.</h5>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>

    <section class="section pt-0">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
            <div class="card custom-form border-0">
              <div class="card-body">
                <form class="rounded shadow p-4">
                  <div class="row mb-2 px-2 border-bottom text-primary font-weight-bold">
                    Dados do Usuário
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>CPF <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-form-input
                            v-model="form.user_document"
                            :state="$v.form.user_document.$error ? false : null"
                            @blur="$v.form.user_document.$touch"
                            v-mask="'###.###.###-##'"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.user_document) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Nome <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.user_name"
                              :state="$v.form.user_name.$error ? false : null"
                              @blur="$v.form.user_name.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.user_name) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>WhatsApp <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.user_phonenumber"
                              :state="$v.form.user_phonenumber.$error ? false : null"
                              @blur="$v.form.user_phonenumber.$touch"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.user_phonenumber) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="form-group">
                        <label>E-mail <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.user_email"
                              :state="$v.form.user_email.$error ? false : null"
                              @blur="$v.form.user_email.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.user_email) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-2 px-2 border-bottom text-primary font-weight-bold">
                    Dados do Fornecedor
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>CNPJ / CPF <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.document"
                              :state="$v.form.document.$error ? false : null"
                              @blur="$v.form.document.$touch"
                              v-mask="['###.###.###-##', '##.###.###/####-##']"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.document) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="form-group">
                        <label>Razão Social / Nome <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.company_name"
                              :state="$v.form.company_name.$error ? false : null"
                              @blur="$v.form.company_name.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.company_name) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>CEP <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.zip_code"
                              :state="$v.form.zip_code.$error ? false : null"
                              @blur="$v.form.zip_code.$touch"
                              v-mask="'##.###-###'"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.zip_code) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Logradouro <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.public_place"
                              :state="$v.form.public_place.$error ? false : null"
                              @blur="$v.form.public_place.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.public_place) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Nº <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.number"
                              :state="$v.form.number.$error ? false : null"
                              @blur="$v.form.number.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.number) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Bairro <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.district"
                              :state="$v.form.district.$error ? false : null"
                              @blur="$v.form.district.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.district) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Estado :</label>
                        <b-select
                            @change="changeState"
                            v-model="form.state_id"
                            :options="options.states"
                            text-field="label"
                            value-field="id"
                            :state="$v.form.state_id.$error ? false : null"
                            @blur="$v.form.state_id.$touch"
                        />
                        <b-form-invalid-feedback>
                          {{ validationMsg($v.form.state_id) }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Cidade :</label>
                        <b-select
                            v-model="form.city_id"
                            :options="options.cities"
                            text-field="label"
                            value-field="id"
                            :state="$v.form.city_id.$error ? false : null"
                            @blur="$v.form.city_id.$touch"
                        />
                        <b-form-invalid-feedback>
                          {{ validationMsg($v.form.city_id) }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Telefone <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.phonenumber"
                              :state="$v.form.phonenumber.$error ? false : null"
                              @blur="$v.form.phonenumber.$touch"
                              v-mask="['(##) ####-####', '(##) #####-####']"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.phonenumber) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-8">
                      <div class="form-group">
                        <label>E-mail <span class="text-danger">*</span></label>
                        <div class="position-relative">
                          <b-input
                              v-model="form.email"
                              :state="$v.form.email.$error ? false : null"
                              @blur="$v.form.email.$touch"
                          />
                          <b-form-invalid-feedback>
                            {{ validationMsg($v.form.email) }}
                          </b-form-invalid-feedback>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label>Processo <span class="text-danger">*</span></label>
                        <b-select
                          v-model="form.process_id"
                          :options="processes"
                          :state="$v.form.process_id.$error ? false : null"
                          @blur="$v.form.process_id.$touch"
                        />
                        <b-form-invalid-feedback>
                          {{ validationMsg($v.form.process_id) }}
                        </b-form-invalid-feedback>
                      </div>
                    </div>

                    <div class="col-md-12">

                      <p class="font-weight-bold mb-0">Finalidade e tratamento dos dados pessoais do cadastro</p>
                      <p class="small text-justify">
                        Os dados pessoais e informações armazenadas pela STARTGOV SOLUÇÕES EM TECNOLOGIA LTDA serão apenas as necessárias para validação do cadastro e respectiva habilitação jurídica dos fornecedores perante os processos licitatórios que tramitarem na respectiva plataforma de licitações, sendo que as informações poderão ser disponibilizadas aos Entes Públicos que utilizam a plataforma de licitações, aos Órgãos Públicos de Controle Externo, ao Poder Judiciário e ao cidadão nos campos de acesso livre à plataforma, nos termos da Lei Geral de Proteção de Dados Pessoais – LGPD (13.709/2018) e na Lei de Acesso a Informação (12.527/2011).
                      </p>

                      <div class="form-group">
                        <b-form-checkbox
                          v-model="check_terms"
                          :state="$v.check_terms.$error ? false : null"
                          @blur="$v.check_terms.$touch"
                        >
                          <template #default>
                            Declaro que li e concordo com os
                            <router-link
                              :to="{ name: 'termos-de-servico' }"
                              class="text-primary text-hover-primary"
                              target="_blank"
                            >
                              <span class="text-primary">Termos de Serviço</span>
                            </router-link>
                            e
                            <router-link
                              :to="{ name: 'politica-de-privacidade' }"
                              class="text-primary text-hover-primary"
                              target="_blank"
                            >
                              <span class="text-primary">Política de Privacidade</span>.
                            </router-link>
                          </template>
                        </b-form-checkbox>
                      </div>

                      <div class="form-group">
                        <b-form-checkbox
                            v-model="check_billing"
                            :state="$v.check_billing.$error ? false : null"
                            @blur="$v.check_billing.$touch"
                        >
                          <template #default>
                            Estou ciente que será gerado uma COBRANÇA conforme a modalidade escolhida.
                          </template>
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12">
                      <b-button
                          :disabled="loading"
                          class="btn-primary w-100"
                          @click="submit"
                      >
                        Cadastrar
                      </b-button>
                    </div>
                    <div class="mt-2 small text-muted col-sm-12">
                      Este site é protegido por reCAPTCHA e a
                      <a href="https://policies.google.com/privacy" target="_blank">Política de Privacidade</a> e os
                      <a href="https://policies.google.com/terms" target="_blank">Termos de Serviço</a> do Google se aplicam.
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {Carousel, Slide} from "vue-carousel";
import {HelpCircleIcon} from "vue-feather-icons";
import cities from "@/utils/cities";
import states from "@/utils/states";
import BiddingSystemPreRegistrationService from "@/services/BiddingSystemPreRegistrationService";
import messageAlert from "@/utils/messageAlert";
import errorDefaultAxios from "@/utils/errorDefaultAxios";
import messagesValidation from "@/utils/messagesValidation";
import {maxLength, minLength, required, requiredIf, sameAs} from "vuelidate/lib/validators";
import { validationMessage } from "vuelidate-messages"
import BiddingSystemProcessService from "@/services/BiddingSystemProcessService";

export default {
  components: {
    Carousel,
    Slide,
    HelpCircleIcon
  },
  data() {
    return {
      loading: false,
      options: {
        states: [{id: null, label: "Selecione um estado"}].concat(states),
        cities: [{id: null, label: "Selecione uma cidade"}]
      },
      form: {
        bidding_system_plan_id: null,
        state_id: null,
        city_id: null,
        process_id: null,
        document: null,
        company_name: null,
        phonenumber: null,
        email: null,
        public_place: null,
        number: null,
        district: null,
        zip_code: null,
        user_name: null,
        user_document: null,
        user_email: null,
        user_phonenumber: null
      },
      processes: [],
      check_terms: false,
      check_billing: false,
    }
  },
  validations: {
    form: {
      user_document: {
        required,
        maxLength: maxLength(14),
      },
      user_name: {
        required,
        maxLength: maxLength(255),
      },
      user_email: {
        required,
        maxLength: maxLength(255),
      },
      user_phonenumber: {
        required,
        maxLength: maxLength(15),
      },
      state_id: {
        required,
      },
      city_id: {
        required,
      },
      process_id: {
        required: requiredIf(function(value) {
          return [5,6,7].includes(value.bidding_system_plan_id)
        }),
      },
      document: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(18),
      },
      company_name: {
        required,
        maxLength: maxLength(255),
      },
      phonenumber: {
        required,
        maxLength: maxLength(15),
      },
      email: {
        required,
        maxLength: maxLength(255),
      },
      public_place: {
        required,
        maxLength: maxLength(255),
      },
      number: {
        required,
        maxLength: maxLength(255),
      },
      district: {
        required,
        maxLength: maxLength(255),
      },
      zip_code: {
        required,
        maxLength: maxLength(10),
      },
    },
    check_terms: {
      check_terms: sameAs(() => true),
    },
    check_billing: {
      check_billing: sameAs(() => true),
    },
  },
  created() {
    const queryParams = JSON.parse(JSON.stringify(this.$route.query))
    if(queryParams.planId) {
      this.form.bidding_system_plan_id = queryParams.planId
    }

    this.getProcess()

    if(queryParams.processId) {
      // this.getProcess()

      // const host = window.location.host.replace('www.', '')
      // let planId = 5
      // if(['licitaalema.com.br'].includes(host)) {
      //   planId = 6
      // }
      // this.form.bidding_system_plan_id = planId
      this.form.process_id = queryParams.processId
    }
  },

  computed: {
    plans() {
      const firstPlan = { value: 5, text: 'Por Processo - R$ 199,00' }

      const host = window.location.host.replace('www.', '')
      if(['licitaalema.com.br'].includes(host)) {
        firstPlan.value = 6
        firstPlan.text = 'Por Processo - R$ 199,00'
      }

      return [
        { value: null, text: 'Selecione um Plano' },
        firstPlan,
        { value: 7, text: 'Dispensa - R$ 99,00' },
      ]
    }
  },
  methods: {
    validationMsg: validationMessage(messagesValidation),

    changePlan(value) {
      this.form.process_id = null

      if([5,6,7].includes(value)) {
        this.getProcess()
      }
    },

    async getProcess() {
      try {
        const data = [
          { value: null, text: 'Selecione um Processo' }
        ]

        this.processes = data

        const params = {
          stageIds: 8,
          origin: window.location.host,
          limit: 9999
        }

        const response = await BiddingSystemProcessService.getAll(params)
        const {data: processes} = response.data.data

        processes.forEach(process => {
          data.push({
            value: process.id,
            text: `${process.modality} Nº ${process.bidding_number} - ${process.object_details}`,
            modality: process.modality
          })
        })

        this.processes = data
      } catch (error) {
        errorDefaultAxios(error)
      }
    },

    changeState(stateId) {
      this.form.city_id = null

      if(stateId === null) {
        return
      }

      const options = [
        {id: null, label: "Selecione uma cidade"}
      ]

      this.options.cities = options.concat(cities.filter(city => city.data.state_id === stateId))
    },

    async submit() {
      try {
        const process = this.processes.find(process => process.value === this.form.process_id)
        let planId = 5
        if(process.modality.search('Dispensa') !== -1) {
          planId = 7
        }

        console.log(planId)
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if(this.$v.check_terms.$invalid) {
            messageAlert('É necessário aceitar os termos para continuar o cadastro.', 'error')
            return
          }
          if(this.$v.check_billing.$invalid) {
            messageAlert('É necessário aceitar os termos da cobrança para continuar o cadastro.', 'error')
            return
          }

          this.loading = true
          const payload = this.form
          payload['origin'] = window.location.host

          const process = this.processes.find(process => process.value === this.form.process_id)
          let planId = 5
          if(process.modality.search('Dispensa') !== -1) {
            planId = 7
          }

          payload['bidding_system_plan_id'] = planId

          payload['g-recaptcha-response'] = await this.$recaptcha('form_provider_register')
          const response = await BiddingSystemPreRegistrationService.create(payload)
          const {message} = response.data

          messageAlert(message)
          await this.$router.push({name: 'home'})
        } else {
          messageAlert('Existem campos inválidos no formulário!', 'error')
        }
      } catch (error) {
        errorDefaultAxios(error)
      } finally {
        this.loading = false
      }
    }
  },
};
</script>


